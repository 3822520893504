@import "../../breakpoints.scss";

.cta {
	align-self: flex-start;
	display: inline-flex;
	font-family: var(--font-brand-body);
	font-size: var(--font-size-level4-mobile);
	color: var(--color-darkest);
	cursor: pointer;
	line-height: 1.1;
	padding: 0;
	text-underline-offset: 2px;

	transition: border 300ms ease-in-out;
	will-change: border;

	@media (min-width: $breakpoint-nav) {
		font-size: var(--font-size-level4);
	}
}

.ctaButton {
	composes: cta;
	background: transparent;
	border: 0;
	cursor: pointer;
}

.ctaLink {
	composes: cta;
	// border-bottom: 1px solid var(--color-darkest);
	// text-decoration: none;
}

.ctaPill,
:global(.cta-pill) {
	background: transparent;
	border: 1px solid var(--color-darkest);
	border-radius: 1.25rem;
	color: var(--color-darkest);
	cursor: pointer;
	display: inline-block;
	font-size: 1rem;
	line-height: 1;
	margin-bottom: 2px;
	padding: 0.6875rem 0.9375rem;
	position: relative;
	text-decoration: none;
	transition: all 150ms ease;
}

.ctaPill span {
	color: var(--color-darkest);
	position: relative;
	z-index: 1;
}

.ctaPill:hover span {
	color: var(--color-lightest);
}

.ctaPill:hover,
:global(.cta-pill:hover) {
	box-shadow: inset 0 -2.5rem 0 0 var(--color-darkest);
	color: var(--color-lightest);
}