@font-face {
    font-family: "telegraf-light";
	src: url("./resources/Telegraf-Light.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "telegraf-regular";
	src: url("./resources/Telegraf-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "telegraf-bold";
	src: url("./resources/Telegraf-Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "signifier-extra-light";
	src: url("./resources/Signifier-Extralight.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "signifier-regular";
	src: url("./resources/Signifier-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}