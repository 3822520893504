@import "../../breakpoints.scss";

.box {
	padding-top: 6.25rem;
}

.form {
	display: flex;
	flex-direction: column;
	margin-bottom: 5rem;
}

.form input[type="submit"] {
	display: inline-flex;
	margin: 1.5rem 0 0;
	width: fit-content;
}

.input {
	background-color: transparent;
	border-bottom: 1px solid var(--color-darkest);
	border-left: 0;
	border-right: 0;
	border-top: 0;
	font-size: 1rem;
	margin: 4rem 0 0;
	max-width: 42rem;
	padding: 0.5rem 0;
	outline: 0;
	width: 100%;
}

.footer {
	max-width: 32rem;
}

.footerLink {
	align-items: center;
	display: flex;
	margin-top: 1.5rem;
}

.footerLink img {
	margin-right: 1.25rem;
}

.error {
	color: var(--color-error);
	margin-top: 0.5rem;
}

.placeholder {
	color: transparent;
	margin-top: 0.5rem;
	user-select: none;
}

.contactImage {
	border-radius: 100%;
}