.placeholder {
	background: var(--color-yellow);
	opacity: 0;
	overflow: hidden;
	position: relative;
	// transform: scaleY(0);
	transition: all 200ms ease;
	width: 100%;
}

.placeholder .video,
.poster {
	left: 0;
	opacity: 0;
	// pointer-events: none;
	position: absolute;
	top: 0;
	transform: scale(1.4);
	transform-origin: bottom;
	transition: all 600ms ease;
	transition-delay: 200ms;
	width: 100%;
	z-index: 1;
}

.videoContainer {
	composes: placeholder;
	background-color: transparent;
	opacity: 1;
	// padding-top: 60.78%; /* Player ratio: 100 / (389 / 640) */  
	position: relative;
	transform: scaleY(1);
}

.videoContainer .poster,
.videoContainer .video {
	opacity: 1;
	transform: scale(1);
}

.foreground {
	background-color: var(--color-yellow);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	transition: all 300ms ease;
	transition-delay: 200ms;
	width: 100%;
	z-index: 1;
}

.videoContainer .foreground {
	height: 0;
}

.poster {
	object-fit: cover;
	z-index: 0;
}