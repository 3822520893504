@import "./fonts.css";
@import "./variables.css";
@import "./breakpoints.scss";

*,
::after,
::before {
	box-sizing: border-box;
}

body {
	background: var(--color-lightest);
	color: var(--color-body-color);
	font-family: var(--font-brand-body);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	overflow-x: hidden;
	padding: 0;
	transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
	will-change: background-color, color;
}

main {
	min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
input,
figcaption,
input::placeholder,
strong,
span,
button,
a {
	color: var(--color-darkest);
	transition: color 300ms ease-in-out;
	will-change: color;
}

h1 {
	font-size: var(--font-size-level1-mobile);
	line-height: 1;

	@media (min-width: $breakpoint-nav) {
		font-size: var(--font-size-level1);
	}
}

h1 em,
h2 em,
h3 em,
h4 em {
	font-family: var(--font-brand-serif);
	font-size: 1.12em;
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
strong {
	font-family: var(--font-brand-regular);
}

h2 {
	font-size: var(--font-size-level2-mobile);
	line-height: 1;
	margin-bottom: 2.25rem;

	@media (min-width: $breakpoint-nav) {
		font-size: var(--font-size-level2);
	}
}

h3 {
	font-size: var(--font-size-level3-mobile);
	line-height: 1;
	margin-bottom: 1.75rem;

	@media (min-width: $breakpoint-nav) {
		font-size: var(--font-size-level3);
	}
}

h4 {
	font-size: var(--font-size-level4-mobile);
	line-height: 1;
	margin-bottom: 1.75rem;

	@media (min-width: $breakpoint-nav) {
		font-size: var(--font-size-level4);
	}
}

h5 {
	font-size: var(--font-size-level5-mobile);
	line-height: 1;
	margin-bottom: 1.5rem;

	@media (min-width: $breakpoint-nav) {
		font-size: var(--font-size-level5);
	}
}

h6 {
	font-size: var(--font-size-level6-mobile);
	line-height: 1;

	@media (min-width: $breakpoint-nav) {
		font-size: var(--font-size-level6);
	}
}

p {
	line-height: 1.57;
}

img {
	display: block;
	max-width: 100%;
}

body.font-color-light {
	--color-darkest: #fff;
	--color-lightest: #000;
}

body.font-color-dark {
	--color-darkest: #000;
	--color-lightest: #fff;
}

// section {
// 	min-height: 25vh;
// }

input:focus::placeholder {
	opacity: 0.8;
}