@import "../../breakpoints.scss";

.relatedContent {
	padding-top: var(--standard-vertical-space-mobile);

	@media (min-width: $breakpoint-nav) {
		padding-top: 0;
	}
}

.leadCopy {
	color: var(--color-darkest);
	font-family: var(--font-brand-serif-regular);
	font-size: 1.135rem;
	margin: -1rem 0 2.25rem;

	@media (min-width: $breakpoint-medium) {
		margin: 0 0 var(--standard-vertical-space);
	}
}

.relatedContent ul {
	gap: 1rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@media (min-width: $breakpoint-nav) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.relatedContent li {
	width: 100%;
}

.imageContainer {
	height: calc(50vw * 1.1);
	margin-bottom: 1.25rem;

	@media (min-width: $breakpoint-nav) {
		height: calc(25vw * 1.1);
	}
}

.imageContainer > div {
	height: 100%;
}

.imageContainer img {
	height: 100%;
	object-fit: cover;
}

.relatedContent a {
	text-decoration: none;
}

.eyebrow {
	margin-bottom: 1.25rem;
}

.content h4 {
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)),
	linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1));
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 1px, 0 1px;
	display: inline;
	line-height: 1.2;
	padding-bottom: 0.125rem;
	transition: background-size 400ms linear;
}

.content:hover h4 {
	@media (min-width: $breakpoint-nav) {
		background-size: 0 1px, 100% 1px;
	}
}

.cta {
	margin-top: var(--standard-vertical-space-mobile);
	text-align: center;
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		margin-top: var(--standard-vertical-space);
	}
}