@import "../../breakpoints.scss";

.copyBlock {
	@media (min-width: $breakpoint-nav) {
		padding: 0;
	}
}

.caseStudyBlock {
	composes: copyBlock;
	max-width: 70rem;

	@media (min-width: $breakpoint-nav) {
		padding: 0 calc(10rem - var(--standard-vertical-space));
	}
}

.block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: var(--standard-vertical-space-mobile);

	@media (min-width: $breakpoint-nav) {
		margin-top: var(--standard-vertical-space);
	}
}

.alignLeft {
	text-align: left;
}

.alignCenter {
	text-align: center;
}

.alignRight {
	text-align: right;
}

.blocks {
	composes: block;
}

.blocks .item {
	border-top: 1px solid var(--color-darkest);
	flex-basis: 100%;
	margin: var(--standard-vertical-space-mobile) 0 0;
	padding: var(--standard-vertical-space-mobile) 0 0;

	@media (min-width: $breakpoint-nav) {
		border-top: 0;
		flex-basis: calc(50% - (var(--standard-horizontal-space) / 2));
		margin: 0;
		padding: 0;
	}
}

.blocks .item:first-child {
	border-top: 0;
	padding: 0;
}

.block ul {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	list-style-position: inside;
}

.block li {
	flex-basis: 100%;
	line-height: 1.2;
	margin-bottom: 0.5rem;
	padding: 0 1rem 0 1.25rem;
	position: relative;

	@media (min-width: $breakpoint-nav) {
		flex-basis: 50%;
	}
}

.block h3 {
	@media (min-width: $breakpoint-nav) {
		margin-top: -2rem;
	}
}

.copyBlock hr {
	color: var(--color-darkest);
	margin: 0;
}

.bigNumber {
	font-family: var(--font-brand-serif);
	font-size: 4rem;
	line-height: 1;

	@media (min-width: $breakpoint-nav) {
		font-size: 12rem;
	}
}

.block em {
	font-family: var(--font-brand-serif);
}

.block p + ul {
	margin-top: 1.25rem;
}

.block li:before {
	content: "";
	border-color: transparent var(--color-darkest);
	border-style: solid;
	border-width: 0.25rem 0 0.25rem 0.33rem;
	display: block;
	height: 0;
	left: 0;
	position: absolute;
	top: 0.3em;
	width: 0;
}

.ctaBlock {
	display: inline-block;
	margin: 1rem 0 2.25rem;
}

.block p + h3 {
	margin-top: 3rem;
}