@import "../../breakpoints.scss";

.benefits {
    @media (min-width: $breakpoint-nav) {
        display: flex;
        justify-content: space-between;
    }
}

.benefits > div:first-child {
	@media (min-width: $breakpoint-nav) {
		width: 57%;
	}
}

.label > p {
    font-family: var(--font-brand-serif-regular);
    font-size: var(--font-size-level5-mobile);
    margin-bottom: 2.75rem;

    @media (min-width: $breakpoint-nav) {
        margin-bottom: 4rem;
    }
}

.benefitsList ol {
    counter-reset: benefit-counter;
    list-style: none;
    margin: 0;
    padding: 0;
}

.benefitsList ol li {
    counter-increment: benefit-counter;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-level4-mobile);
    margin-bottom: 2.125rem;

    @media (min-width: $breakpoint-nav) {
        flex-direction: row;
        font-size: var(--font-size-level4);
        line-height: 1.2;
        margin-bottom: 2.25rem;
    }
}

.benefitsList ol li::before {
    content: "0" counter(benefit-counter);
    font-family: var(--font-brand-regular);
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 1rem;

    @media (min-width: $breakpoint-nav) {
        margin: 1.75rem 2.75rem 0 0;
    }
}

.rolesContainer {
    @media (min-width: $breakpoint-nav) {
        max-width: 37.5rem;
        width: 33.34%;
    }
}

.rolesList li {
    margin-bottom: 1.875rem;
}

.rolesList a {
    display: flex;
    font-family: var(--font-brand-regular);
    font-size: 1.5rem;
    line-height: 1.47;
    justify-content: space-between;
    text-decoration: none;

    @media (min-width: $breakpoint-nav) {
        font-size: 1.625rem;
    }
}

.rolesList a::after {
    content: "↗";
    line-height: 1;
    margin-left: 1rem;
}