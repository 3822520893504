@import "../../breakpoints.scss";

.heading > div {
	margin-bottom: 2.125rem;
}

.heading {
	@media (min-width: $breakpoint-nav) {
		margin-bottom: 11.688rem;
	}
}