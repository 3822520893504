@import "../../breakpoints.scss";

.top {
	// padding-top: var(--standard-vertical-space-mobile);

	@media (min-width: $breakpoint-nav) {
		padding-top: var(--standard-vertical-space);
	}
}

.left {
	padding-left: var(--standard-horizontal-space-mobile);

	@media (min-width: $breakpoint-nav) {
		padding-left: var(--standard-horizontal-space);
	}
}

.right {
	padding-right: var(--standard-horizontal-space-mobile);

	@media (min-width: $breakpoint-nav) {
		padding-right: var(--standard-horizontal-space);
	}
}

.bottom {
	padding-bottom: var(--standard-vertical-space-mobile);

	@media (min-width: $breakpoint-nav) {
		padding-bottom: var(--standard-vertical-space);
	}
}