@import "../../breakpoints.scss";

.hideOverflow {
	overflow: hidden;
}

.carousel {
	cursor: grab;
	display: flex;
	position: relative;
}

.slide {
	align-self: flex-end;
}

.hintContainer {
	height: 100%;
	position: absolute;
	width: 100%;
}

.carousel:hover .dragHint {
	background: var(--color-yellow);
	border-color: var(--color-yellow);
}

.dragHint {
	display: none;

	@media (min-width: $breakpoint-nav) {
		align-items: center;
		background: transparent;
		border: 1px solid var(--color-literal-darkest);
		border-radius: 50%;
		color: var(--color-literal-darkest);
		display: flex;
		font-size: 1.125rem;
		height: 4rem;
		justify-content: center;
		left: 50%;
		line-height: 1;
		opacity: 1;
		pointer-events: none;
		position: absolute;
		top: 35%;
		width: 4rem;
	}
}

.hide {
	composes: dragHint;
	opacity: 0;
}