:root {
	--color-lightest: #FFF;
	--color-darkest: #000;
	--color-literal-lightest: #fff;
	--color-literal-darkest: #000;

	--color-yellow: #FFFF00;

	--color-error: #FF4713;

	--color-body-background: var(--color-lightest);
	--color-border: var(--color-mid);

	--site-max-width: 90rem;

	--standard-horizontal-space-mobile: 0.75rem;
	--standard-vertical-space-mobile: 5rem;

	--standard-horizontal-space: 3.4375rem;
	--standard-vertical-space: 4.84375rem;

	--font-brand-body: "telegraf-light", "Helvetica Neue", Helvetica, Arial, sans-serif;
	--font-brand-regular: "telegraf-regular", monospace;
	--font-brand-bold: "telegraf-bold", monospace;

	--font-brand-serif: "signifier-extra-light", serif;
	--font-brand-serif-regular: "signifier-regular", serif;

	--font-size-level1-mobile: 3.5rem;
	--font-size-level2-mobile: 2.66rem;
	--font-size-level3-mobile: 2rem;
	--font-size-level4-mobile: 1.75rem;
	--font-size-level5-mobile: 1.25rem;
	--font-size-level6-mobile: 1.25rem;

	--font-size-level1: 6.25rem;
	--font-size-level2: 5.9375rem;
	--font-size-level3: 5.25rem;
	--font-size-level4: 2.875rem;
	--font-size-level5: 1.6875rem;
	--font-size-level6: 1.6875rem;
	
	--screen-size-large: 75rem;
}