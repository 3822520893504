@import "../../breakpoints.scss";

.heading {
	margin: 1rem 0 2.25rem;
	max-width: 57.812rem;

	@media (min-width: $breakpoint-nav) {
		margin: 0 0 7.75rem;
	}
}

.removeMargin {
	margin-bottom: 0;
}