@import "../../../breakpoints.scss";

.footerTop,
.footerBottom {
	display: flex;
	flex-wrap: wrap;
}

.footerBottom {
	justify-content: space-between;
	font-size: 0.625rem;
}

.terms {
	display: flex;
	flex-direction: column;
}

.terms a {
	line-height: 1;
	margin-bottom: 0.625rem;
	text-decoration: none;
}

.social {
	display: flex;
	flex-direction: column;
	margin-bottom: 3.75rem;

	@media (min-width: $breakpoint-nav) {
		margin-bottom: 0;
	}
}

.copyright {
	line-height: 1;
}

.newsletter,
.connect {
	@media (min-width: $breakpoint-nav) {
		width: 41.67%;
	}
}

.newsletter p,
.connect p {
	@media (min-width: $breakpoint-nav) {
		width: 80%;
	}
}

.newsletterForm {
	border-bottom: 1px solid var(--color-darkest);
	display: flex;
	justify-content: space-between;
	margin-top: 2.2rem;
	width: 100%;
}

.newsletterForm input[type="email"] {
	background: transparent;
	border: 0;
	flex: 1;
	font-family: var(--font-brand-body);
	font-size: 1.125rem;
	outline: 0;
	padding: 0.625rem 0.5rem 0.625rem 0;
}

.newsletterForm input[type="submit"] {
	background: transparent;
	border: 0;
	cursor: pointer;
	font-family: var(--font-brand-body);
	font-size: 1.125rem;
	padding: 0.625rem 0;
	outline: 0;
}

footer strong {
	display: block;
	margin-bottom: 1rem;
}