@import "../../breakpoints.scss";

.copy {
    @media (min-width: $breakpoint-medium) {
        width: 58%;
    }
}

.relativePosition {
    position: relative;
}

.image1 {
    
    @media (min-width: $breakpoint-nav) {
        position: absolute;
        top: 0;
        width: 58%;
        z-index: 2;
    }
}

.image2 {
    
    @media (min-width: $breakpoint-nav) {
        margin-left: auto;
        padding-top: 22%;
        width: 58%;
    }
}

.caption {
    display: flex;
	flex-direction: column;
	padding: 1.5rem 0;
}

.head {
    font-size: 1.625rem;
    margin-bottom: 1.75rem;
}
