.headline {
	max-width: 42.375rem;
}

.subhead {
	font-size: 1.625rem;
	max-width: 47.5rem;
}

.cta {
	align-items: center;
	display: flex;
	margin-top: 2.5rem;
}

.cta img {
	border-radius: 50%;
	display: inline-block;
	height: 4rem;
	width: 4rem;
}

.cta > div {
	display: inline;
	margin-right: 1.25rem;
	width: auto;
}