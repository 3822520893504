@import "../../breakpoints.scss";

.heading {
	margin-bottom: 3.375rem;
}

.grid {
    display: flex;
	flex-direction: column;
    flex-wrap: wrap;
	justify-content: space-between;
	
	@media (min-width: $breakpoint-nav) {
		flex-direction: row;
	}
}

.grid::after {
	
	
	@media (min-width: $breakpoint-nav) {
		content: "";
		width: 49%;
	}

	@media (min-width: $breakpoint-medium) {
		width: 31%;
	}
  }

.grid li {
    border-top: 1px solid black;
	padding: 1.5rem 0;
	
	@media (min-width: $breakpoint-nav) {
		width: 48%;
	}
	
	@media (min-width: $breakpoint-medium) {
		width: 31%;
	}
}

.grid li.extraWide {
	
	@media (min-width: $breakpoint-medium) {
		width: 65.5%;

	}
}

.clientContainer {
    display: flex;
	justify-content: space-between;
	flex-direction: column;
	
	@media (min-width: $breakpoint-medium) {
		flex-direction: row;
	}
}

.linkLabel {
	font-family: var(--font-brand-bold);
	font-size: 1.125rem;
}

.clientContainer h4 {
	margin: 1.375rem 0 1.25rem;
}

.clientContainer a {
	margin-top: 1.25rem;
	text-decoration: none;
}

.imageContainer,
.copyWithImage {
	width: 100%;

	@media (min-width: $breakpoint-medium) {
		width: 47.3%;
	}
}

.imageContainer {
	margin-top: 1.125rem;

	@media (min-width: $breakpoint-medium) {
		margin-top: 0;
		width: 47.3%;
	}
}

.emailContainer {
    padding-top: 3.125rem;
}

.newsletter {
	@media (min-width: $breakpoint-nav) {
		width: 41.67%;
	}
}

.newsletter p {
	width: 80%;
}

.newsletterForm {
	border-bottom: 1px solid var(--color-darkest);
	display: flex;
	justify-content: space-between;
	margin-top: 2.2rem;
	width: 100%;
}

.newsletterForm input[type="email"] {
	background: transparent;
	border: 0;
	flex: 1;
	font-family: var(--font-brand-body);
	font-size: 1.125rem;
	outline: 0;
	padding: 0.625rem 0.5rem 0.625rem 0;
}

.newsletterForm input[type="submit"] {
	background: transparent;
	border: 0;
	cursor: pointer;
	font-family: var(--font-brand-body);
	font-size: 1.125rem;
	padding: 0.625rem 0;
	outline: 0;
}