@import "../../breakpoints.scss";

.mediaBlock {
	margin-bottom: 1rem;
}

.blocks {
	align-items: flex-end;
	display: grid;
	grid-gap: 1.875rem;
	grid-template-columns: repeat(2, 1fr);
}