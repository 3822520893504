@import "../../breakpoints.scss";

.hero {
	margin-top: 1.125rem;

	@media (min-width: $breakpoint-nav) {
		margin-top: var(--standard-vertical-space);
	}
}

.portalHero {
	margin-top: 2.125rem;
}

.copy {
	@media (min-width: $breakpoint-nav) {
		padding-right: calc(var(--standard-horizontal-space) / 2);
	}
}

.copy p {
	@media (min-width: $breakpoint-nav) {
		max-width: 40rem;
	}
}

.hasImage {
	display: flex;
	flex-wrap: wrap;
}

.hasImage.imageLeft {
	flex-direction: row-reverse;
}

.hasImage .copy {
	font-size: 1.25rem;
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		font-size: 1.6875rem;
		width: 33.33%;
	}
}

.hasImage .imageContainer,
.videoWrapper {
	margin-top: 2.25rem;
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		margin-top: 0;
		width: 66.67%;
	}
}