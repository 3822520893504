@import "../../breakpoints.scss";

.mediaBlock {
	margin: var(--standard-horizontal-space-mobile) 0;

	@media (min-width: $breakpoint-nav) {
		margin: var(--standard-horizontal-space) 0;
	}
}

.fullBlock {
	margin-bottom: 1rem;
}