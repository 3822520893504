.pattern {
	background-size: cover;
	height: 100vh;
	left: 0;
	opacity: 1;
	pointer-events: none;
	position: fixed;
	top: 0;
	visibility: visible;
	transition: all 200ms ease;
	width: 100vw;
	z-index: 1;
}

.hide {
	composes: pattern;
	opacity: 0;
	visibility: hidden;
}