@import "../../breakpoints.scss";

.hasImage,
.textOnly {
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
}

.hasImage.imageLeft {
	flex-direction: row-reverse;
}

.imageContainer,
.copy {
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		width: 50%;
	}
}

.copy {
	margin: var(--standard-vertical-space-mobile) 0 0;

	@media (min-width: $breakpoint-nav) {
		margin: auto 0;
	}
}

.textOnly .copy {
	margin: 0;
	width: 100%;
}

.tout li:before {
	content: "\25B6 \ ";
	font-size: 0.3125rem;
	vertical-align: top;
}

.hasImage .copy {
	z-index: 1;
}

.hasImage .copy p {
	margin-bottom: 2rem;
	max-width: 40rem;
}

.textOnly .copy p:last-child {
	margin-bottom: 0;
}

.copy ul {
	display: flex;
	flex-wrap: wrap;
	max-width: 40rem;
}

.copy li {
	width: 50%;
}

.caption {
	display: flex;
	margin-top: 1.5rem;
}

.caption.indent {
	padding-left: var(--standard-horizontal-space-mobile);

	@media (min-width: $breakpoint-nav) {
		padding-left: var(--standard-horizontal-space);
	}
}

.caption p:first-child {
	flex: 1;
}

.caption button {
	background-color:transparent;
	border:none;
	font-size: inherit;
	outline:none;
}

.textOnly .copy p {
	margin-bottom: 2rem;
	max-width: 40rem;
}


// Carousel styling below

.carousel {
	display: flex;
	// margin-right: 55px;
	overflow: hidden;
	position: relative;
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		width: 50%;
	}
}

.hintContainer {
	height: 100%;
	position: absolute;
	width: 100%;
}

.carousel:hover {
	cursor: grab;
}

.carousel:hover .dragHint {
	background: var(--color-yellow);
	border-color: var(--color-yellow);
}

.dragHint {
	align-items: center;
	background: transparent;
	border: 1px solid var(--color-literal-darkest);
	border-radius: 50%;
	color: var(--color-literal-darkest);
	display: flex;
	font-size: 1.125rem;
	height: 4rem;
	justify-content: center;
	left: 50%;
	line-height: 1;
	opacity: 1;
	position: absolute;
	top: 35%;
	// transform: translate(-50%);
	width: 4rem;

	// transition: opacity 200ms ease-in-out, background 300ms ease-in-out, 300ms ease-in-out, color 300ms ease-in-out;
	// will-change: opacity, background, border, color;
}

.hide {
	composes: dragHint;
	opacity: 0;
}

// .ctaBlock {
// 	display: block;
// 	margin-bottom: 2px;
// }