@import "../../breakpoints.scss";

.projectGrid {
	margin-top: 6.25rem;
}

.projectGrid ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.projectGrid ul::after {
	@media (min-width: $breakpoint-nav) {
		content: "";
		width: calc(33.33% - 1rem);
	}
}

.projectGrid .item {
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		width: calc(33.33% - 1rem);
	}
}

.projectGrid .largeItem {
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		width: calc(66.67% - 0.5rem);
	}
}

.imageContainer {
	margin-bottom: 1.25rem;

	@media (min-width: $breakpoint-nav) {
		height: calc(33.33vw * 1.25);
	}
}

.imageContainer > div {
	height: 100%;
}

.imageContainer img {
	height: 100%;
	object-fit: cover;
}

.projectGrid a {
	text-decoration: none;
}

.eyebrow {
	margin-bottom: 1.25rem;
}

.content h4 {
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)),
	linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1));
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 1px, 0 1px;
	display: inline;
	line-height: 1.2;
	padding-bottom: 0.125rem;
	transition: background-size 400ms ease-out;
}

.content:hover h4 {
	@media (min-width: $breakpoint-nav) {
		background-size: 0 1px, 100% 1px;
	}
}