@import "../../breakpoints.scss";

.gridContainer {
	position: relative;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.grid::after {
    
    @media (min-width: $breakpoint-nav) {
        content: "";
		width: 48%;
	}
	
	@media (min-width: $breakpoint-medium) {
		width: 31%;
	}
  }

.grid li {
    width: 100%;

    @media (min-width: $breakpoint-nav) {
		width: 48%;
	}
	
	@media (min-width: $breakpoint-medium) {
		width: 31%;
	}
}

.caption {
    display: flex;
	flex-direction: column;
    padding: 1.25rem 0;
    
    @media (min-width: $breakpoint-nav) {
		padding: 1.5rem 0;
	}
}

.caption a {
    text-decoration: none;
}

.head {
	display: flex;
	justify-content: space-between;
}

.eyebrowLeft {
	flex: 1;
    font-family: var(--font-brand-regular);
	font-size: 1.125rem;
	margin-bottom: 1.25rem;
}

.head > button {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	margin: 0 0 1.25rem;
	outline: none;
	padding: 0;
	width: 2.5rem;
}

.head > button:after {
	background-color: transparent;
	border: 1px solid var(--color-literal-darkest);
	border-radius: 50%;
	content: "";
	display: inline-block;
	height: 0.75rem;
	margin-left: 0.375rem;
	width: 0.75rem;
}

.head > button.full:after {
	background-color: var(--color-literal-darkest);
}

.modal {
	background-color: var(--color-yellow);
	display: block;
	padding: var(--standard-horizontal-space-mobile);
	position: absolute;

	@media (min-width: $breakpoint-nav) {
		padding: var(--standard-horizontal-space);
	}
}

.hide {
	composes: modal;
	display: none;
}