@import "../../breakpoints.scss";

.lightText,
.lightText h1 {
	color: var(--color-darkest);
}

.darkText,
.darkText h1 {
	color: var(--color-darkest);
}

.copy {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: var(--standard-vertical-space-mobile);

	@media (min-width: $breakpoint-nav) {
		flex-wrap: nowrap;
		margin-top: 10rem;
	}
}

.clientName {
	white-space: nowrap;
}

.projectName {
	@media (min-width: $breakpoint-nav) {
		padding-left: calc(64% - 50rem);
		width: 82%;
	}
}

.projectName h1 {
	max-width: 50rem;
}

.clientName,
.emptyDiv {
	font-family: var(--font-brand-bold);
	margin: 0 0 2.25rem;
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		margin: 0.5rem 0 0 0;
		width: 18%;
	}
}

.clientName {
	padding-right: 1.5rem;
}

.emptyDiv {
	height: 0;
	margin: 0;
	user-select: none;
	visibility: hidden;
	opacity: 0;
}

.video {
	left: 0;
	position: absolute;
	top: 0;
}

.videoWrapper {
	padding-top: 60.78%; /* Player ratio: 100 / (389 / 640) */  
	position: relative;
}