@import "../../breakpoints.scss";

.quoteMark:before {
	content: "“";
	display: block;
	font-size: 5.9375rem;
	margin-top: 2rem;
	text-align: center;
	width: 100%;
}

.quoteBlock {
	margin: 7.5rem 0;
	text-align: center;
	
	@media (min-width: $breakpoint-nav) {
		margin: 15rem 0 9rem;
	}
}

.quoteBlock figcaption {
	font-family: var(--font-brand-serif);
	font-size: 1.125rem;
	margin-top: 2.25rem;

	@media (min-width: $breakpoint-nav) {
		margin-top: 4rem;
	}
}