@import "../../breakpoints.scss";

.imageGrid {
	margin-top: 2.65625rem;
}

.grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.leadCopy {
	margin: -1rem 0 2.25rem;

	@media (min-width: $breakpoint-medium) {
		margin: 7.5rem 0 4rem;
	}
}

.leadCopy p {
	font-family: var(--font-brand-serif-regular);
	font-size: 1.135rem;
}

.grid h4 {
	margin-bottom: 2.25rem;
}

.grid p {
	margin-bottom: 2.25rem;
	line-height: 1.75;
}

.grid li {
	display: flex;
	flex-direction: column;
	margin-bottom: 2.75rem;

	@media (min-width: $breakpoint-medium) {
		margin-bottom: 7.5rem;
		width: calc(50% - 1em);
	}
}

.width3 li {
	@media (min-width: $breakpoint-medium) {
		margin-bottom: 7.5rem;
		width: calc(33.33% - 1em);
	}
}

.textAbove .imageContainer {
	margin: auto 0 0;
	padding-top: 1.25rem;
}

.textBelow .imageContainer {
	margin: 0 0 1.25rem;
}

.eyebrow {
	margin-bottom: 1.25rem;
}

.eyebrow p {
	margin: 0;
}

.textAbove .item {
	display: flex;
	flex-direction: column;
}

.textBelow li,
.textBelow li .ctaLink {
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-end;
}

.textBelow h4 {
	margin: 0 0 2.25rem;
}

.copy {
	max-width: 43rem;
}

.copy p:last-child {
	margin-bottom: 0;
}

.ctaLink {
	text-decoration: none;
}

.ctaLink h4 {
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)),
	linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1));
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 1px, 0 1px;
	display: inline;
	line-height: 1.2;
	padding-bottom: 0.125rem;
	transition: background-size 400ms ease-out;
}

.ctaLink:hover h4 {
	@media (min-width: $breakpoint-nav) {
		background-size: 0 1px, 100% 1px;
	}
}