@import "../../breakpoints.scss";

.textImageCarousel {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: hidden;
}

.copy,
.carousel {
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		width: calc(50% - 1rem);
	}
}

.copy {
	margin-bottom: 2.25rem;
	z-index: 1;

	transition: background-color 300ms ease-in-out;
	will-change: background-color;

	@media (min-width: $breakpoint-nav) {
		margin: auto 0;
	}
}

.copy p {
	max-width: 30rem;
}

.copy p + p  {
	margin-top: 2rem;
}

.copy ul {
	list-style-type: disc;
	padding-left: 1rem;
}

.carousel {
	display: flex;
	overflow: hidden;
	position: relative;
}

.narrowCarousel {
	composes: carousel;
}

.hintContainer {
	height: 78%;
	position: absolute;
	width: 100%;
}

.narrowCarousel .hintContainer {
	height: 70%;
}

.singleSlide {
	width: calc(100% - 60px);
}

.slideCaption {
	padding: 1.5rem 0 0;
}

.slideCaption a {
	text-decoration: none;
	white-space: pre-wrap;
}

.slide a {
	background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)),
	linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1));
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 1px, 0 1px;
	transition: background-size 400ms linear;
	text-decoration: none;
}

.slide:hover a {
	@media (min-width: $breakpoint-nav) {
		background-size: 0 1px, 100% 1px;
	}
}

.eyebrowLeft {
	font-size: 1.125rem;
	margin-bottom: 1.75rem;
}

.eyebrowLeft,
.eyebrowCenter,
.count {
	line-height: 1;
}

.head {
	display: flex;
	justify-content: space-between;
}

.carousel:hover {
	cursor: grab;
}

.carousel:hover .dragHint {
	background: var(--color-yellow);
	border-color: var(--color-yellow);
}

.dragHint {
	display: none;

	@media (min-width: $breakpoint-nav) {
		align-items: center;
		background: transparent;
		border: 1px solid var(--color-literal-darkest);
		border-radius: 50%;
		color: var(--color-literal-darkest);
		display: flex;
		font-size: 1.125rem;
		height: 4rem;
		justify-content: center;
		left: 50%;
		line-height: 1;
		opacity: 1;
		pointer-events: none;
		position: absolute;
		top: 35%;
		// transform: translate(-50%);
		width: 4rem;
	
		// transition: opacity 200ms ease-in-out, background 300ms ease-in-out, 300ms ease-in-out, color 300ms ease-in-out;
		// will-change: opacity, background, border, color;
	}
}

.hide {
	composes: dragHint;
	opacity: 0;
}

.ctaBlock {
	margin: 2.25rem 0;
}