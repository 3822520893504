@import "../../../breakpoints.scss";

:global(.modal-open) {
	overflow: hidden;
}

nav {
	align-items: center;
	background: var(--color-lightest);
	display: flex;
	height: 6.25rem;
	justify-content: space-between;
	padding: 0 1rem;
	position: sticky;
	top: 0;
	transform: translateZ(0);
	width: 100%;
	z-index: 1;

	transition: background-color 300ms ease-in-out, transform 300ms ease;
	will-change: background-color;

	@media (min-width: $breakpoint-nav) {
		padding: 0 3.4375rem;
	}
}

.hide {
	transform: translate3d(0, -6.3rem, 0);
}

.logo {
	height: 1.25rem;
	filter: invert(0%);

	transition: filter 300ms ease-in-out;
	will-change: filter;
}

:global(body.font-color-light) .logo {
	filter: invert(100%);
}

.menu {
	display: flex;
	flex-direction: column;
	margin-top: 4rem;
	width: 80%;

	@media (min-width: $breakpoint-nav) {
		flex-direction: row;
		margin-top: 0;
		width: auto;
	}
}

.menu li {
	margin-left: 1rem;

	@media (min-width: $breakpoint-nav) {
		margin-left: 1.25rem;
	}
}

.navLink {
	color: var(--color-darkest);
	display: block;
	font-size: 1.75rem;
	line-height: 1.1;
	margin-bottom: 1.875rem;
	padding: 0.5rem 0;
	text-decoration: none;

	transition: background-color 300ms ease-in-out;
	will-change: background-color;

	@media (min-width: $breakpoint-nav) {
		font-size: 0.875rem;
		margin-bottom: 0;
	}
}

.activeLink {
	font-family: var(--font-brand-bold);
}

.navButton {
	composes: navLink;
	align-items: flex-start;
	background: transparent;
	border: 0;
	cursor: pointer;
	display: flex;
	font-family: var(--font-brand-regular);
	height: 100%;
	outline: none;
	text-align: left;

	@media (min-width: $breakpoint-nav) {
		align-items: center;
	}
}

.menuButton {
	background-color: transparent;
	border: 0;
	font-family: var(--font-brand-body);
	font-size: 1rem;
	outline: none;

	@media (min-width: $breakpoint-nav) {
		display: none;
	}
}

.hideHeader {
	opacity: 0;
	pointer-events: none;
	transition: all 500ms ease;
}

.showHeader,
.hideHeader {
	background-color: var(--color-lightest);
	height: 0;
	transition: all 500ms ease, background-color 300ms ease-in-out;
	will-change: background-color;

	@media (min-width: $breakpoint-nav) {
		display: flex;
		height: auto;
		margin-left: auto;
		opacity: 1;
		pointer-events: all;
	}

	@media (max-width: $breakpoint-nav-max) {
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		left: 0;
		height: 0;
		overflow: hidden;
		position: absolute;
		top: 6.25rem;
		width: 100vw;
	}
}

.showHeader {
	composes: hideHeader;
	height: calc(100vh - 5.1875rem);
	opacity: 1;
	pointer-events: all;

	@media (min-width: $breakpoint-nav) {
		height: auto;
	}
}

.modal {
	overflow-y: auto
}

.modalOuter {
	background-color: var(--color-yellow);
	color: var(--color-literal-darkest);
	margin-left: auto;
	min-height: 54rem;
	overflow: auto;
	width: 100vw;

	@media (min-width: $breakpoint-nav) {
		width: 80vw;
	}
}

.modal h1,
.modal p,
.modal button,
.modal strong,
.modal input,
.modal span {
	color: var(--color-literal-darkest);
}

.modal h1 {
	margin-bottom: 2rem;
	max-width: 35rem;
}

.modal p {
	max-width: 25rem;
}

.closeButtonContainer {
	display: flex;
	justify-content: flex-end;
	padding: 2.5rem var(--standard-horizontal-space-mobile);
	width: 100%;

	@media (min-width: $breakpoint-nav) {
		padding: 2.5rem 3rem;
	}
}

.closeButton {
	background: transparent;
	border: 0;
	cursor: pointer;
	font-size: 1.125rem;
}

.modalContent {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (min-width: $breakpoint-nav) {
		flex-direction: row;
	}
}

.copy,
.form {
	@media (min-width: $breakpoint-nav) {
		width: calc(50% - 1rem);
	}
}

.form {
	padding-bottom: var(--standard-vertical-space);

	@media (min-width: $breakpoint-nav) {
		padding-bottom: 0;
	}
}

.form form {
	display: flex;
	flex-direction: column;
}

.form input {
	background: transparent;
	border: 0;
	border-bottom: 1px solid var(--color-literal-darkest);
	border-radius: 0;
	font-family: var(--font-brand-body);
	font-size: 1.125rem;
	margin-bottom: 2rem;
	outline: none;
	padding: 0.625rem 0;
}

.split {
	display: flex;
	justify-content: space-between;
}

.split input {
	width: calc(50% - 0.9375rem);
}

.form strong {
	font-weight: 700;
	margin-bottom: 1rem;
}

.checkboxes {
	margin-bottom: 1rem;
}

.form textarea {
	background: transparent;
	border: 0;
	border-bottom: 1px solid var(--color-literal-darkest);
	font-family: var(--font-brand-body);
	font-size: 1.125rem;
	margin-bottom: 2rem;
	padding: 0.625rem 0;
	outline: none;
}

.form textarea::placeholder,
.form input::placeholder {
	color: var(--color-literal-darkest);
}

.form input[type="submit"] {
	display: block;
	border: 1px solid var(--color-literal-darkest);
	border-radius: 1.25rem;
	cursor: pointer;
	padding: 0.5rem 0.75rem;
	transition: all 150ms ease;
}

.form input[type="submit"]:hover {
	box-shadow: inset 0 -2.5rem 0 0 var(--color-literal-darkest);
	color: var(--color-lightest);
}

.form [type="checkbox"] {
	position: absolute;
	left: 0;
	opacity: 0;
}

.form [type="checkbox"] + label:before {
	content: "";
	border: 1px solid var(--color-literal-darkest);
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	height: 0.875rem;
	margin-right: 0.3125rem;
	opacity: 1;
	width: 0.875rem;
}

.form [type="checkbox"]:checked + label:before {
	background: var(--color-literal-darkest);
}

.form [type="checkbox"] + label {
	cursor: pointer;
	display: inline-block;
	margin-right: 0.625rem;
}

.bottom {
	margin-top: auto;
	padding: var(--standard-vertical-space-mobile) 0;
	display: flex;
}

.bottom a {
	color: var(--color-literal-darkest);
}

.bottom div {
	display: flex;
	flex-direction: column;
	margin-right: 20%;
}

.bottom div:last-child {
	margin-right: 0;
}

.bottom a {
	text-decoration: none;
}

:global(.menu-open) {
	background-color: var(--color-literal-lightest) !important;
	color: var(--color-literal-darkest);
}


:global(.menu-open) .logo {
	filter: invert(0%) !important;
}

:global(.menu-open) .menuButton {
	color: var(--color-literal-darkest);
}

:global(.menu-open #menu) {
	background-color: var(--color-literal-lightest) !important;
}

:global(.menu-open) .navLink {
	color: var(--color-literal-darkest);
}

:global(.nav-hidden) {
	opacity: 0;
	pointer-events: none;
	visibility: hidden;
}

:global(#cta-holder) {
	opacity: 0;
	position: absolute;
	right: 1rem;
	transition: opacity 200ms ease 350ms;

	@media (min-width: $breakpoint-nav) {
		right: 3.4375rem;
	}
}

.nav :global(#cta-holder.visible) {
	opacity: 1;
}